import { useLocation } from "react-router-dom";

const socials = {
  twitter: "https://twitter.com/themetakey",
  instagram: "https://www.instagram.com/the_metakey",
  linkedin: "https://linkedin.com/company/78832366"
};

const Email: React.FC<any> = () => {
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const name = params.get('name');

  const email = params.get('email');
  const phoneNumber = params.get('phoneNumber');
  const title = params.get('title');
  const image = params.get('image')!;

  return (
    <div className="sig">

      <div className="containertop">
        <table cellPadding="0" cellSpacing="0">
          <tr>
            <td className="img-left" style={{ paddingLeft: '4px', paddingTop: "4px" }}>
              <img className="pfp"
                src={image}
                alt="logo 2" />
            </td>
            <td className="content">
              <table className="tdcontenttable">
                <tr style={{ width: "50px" }}>
                  <td className="name">{name}</td>
                </tr>
                <tr>
                  <td className="designation">{title}</td>
                </tr>
                {phoneNumber && <tr>
                  <td className="contact" style={{ marginTop: "6px" }}>
                    <img alt="" width={200} height={200} src="https://res.cloudinary.com/metakey/image/upload/v1698099165/email.themetakey.com/phone_el69co.png" />
                    <div className="contentFieldWrapper">
                      <span>{phoneNumber}</span>
                    </div>
                  </td>
                </tr>}
                <tr>
                  <td className="contact">
                    <img alt="" width={200} height={200} src="https://res.cloudinary.com/metakey/image/upload/v1698099165/email.themetakey.com/envelope_r8q3h9.png" />
                    <div className="contentFieldWrapper">
                      <span>{email}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="contact">

                    <img alt="" width={200} height={200} src="https://res.cloudinary.com/metakey/image/upload/v1698099165/email.themetakey.com/globe_zp5esx.png" />
                    <div className="contentFieldWrapper">
                      <a href="https://themetakey.com" style={{ textDecoration: "none", color: "blue" }}>
                        <span>themetakey.com</span>
                      </a>
                    </div>

                  </td>

                </tr>
              </table>
            </td>
            <td className="img-right" style={{ paddingTop: "4px" }}>

              <img className="secondary-logo"
                src="https://res.cloudinary.com/metakey/image/upload/v1698099273/email.themetakey.com/FullBlueLogo_aqe4wm.png"
                alt="logo 2" />

            </td>
          </tr>
        </table>
      </div>
      <div className="containerbottom" >
        <table cellPadding="0" cellSpacing="0">
          <tr>
            <td colSpan={3}>
              <table className="company-container" cellPadding="0" cellSpacing="0">
                <tr style={{ padding: 1 }}>
                  <td style={{ textAlign: 'left', fontSize: 25 }}>
                    &nbsp;Metakey
                  </td>
                  <td className="icons">
                    <a href={socials.twitter} title="Twitter">
                      <div className="iconWrapper">
                        <img alt="" width={14} height={14} className="icon" src="https://res.cloudinary.com/metakey/image/upload/v1698099165/email.themetakey.com/x_fybtfs.png" />
                      </div>
                    </a>
                    <a href={socials.linkedin} title="LinkedIn">
                      <div className="iconWrapper">
                        <img alt="" width={15} height={15} className="icon" src="https://res.cloudinary.com/metakey/image/upload/v1698099165/email.themetakey.com/linkedin_akx1oc.png" />
                      </div>
                    </a>

                  </td>

                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div >
  );
}

export default Email
