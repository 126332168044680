import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Email from './pages/Email';
import useWindowDimensions from './useWindowDimensions';

const App: React.FC = () => {


  const { height, width } = useWindowDimensions();

  if (width < 550 || height < 700) {
    return (
      <div id="heading-section">
        <img id="logo-heading" alt="" src="/logo.jpg"></img>
        <h3 id="form-heading">Site Not Available For This Viewport Size</h3>
      </div>
    )
  }
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signature" element={<Email />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;


