import { useState } from "react"
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const [name, setName] = useState<null | string>(null)
  const [title, setTitle] = useState<null | string>(null)
  const [email, setEmail] = useState<null | string>(null)
  const [phoneNumber, setPhoneNumber] = useState<null | string>(null)
  const [selectedFile, setSelectedFile] = useState<null | any>(null)
  const [error, setError] = useState<null | string>(null)

  const navigate = useNavigate();


  // On file select (from the pop up)
  const onFileChange = (event: any) => {
    setError(null)
    // Update the state
    setSelectedFile(event.target.files[0]);

  };

  // On file upload (click the upload button)
  const generateSignature = async () => {
    if (!(name && title && email && selectedFile)) {
      setError("Form Incomplete")
      return
    }


    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "file",
      selectedFile as any,
      (selectedFile as any).name
    );
    formData.append(
      "api_key",
      "8T6HS22hyDxUcljI3-khFsuyy3Q"
    )
    formData.append("upload_preset", "lwwmbfzx");

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object
    await axios.post("https://api.cloudinary.com/v1_1/metakey/image/upload", formData, {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    }).then(response => {
      const data = response.data;
      const essential = {email, name, title, image: data.secure_url }
      const params = new URLSearchParams(phoneNumber? { ...essential, phoneNumber}: essential)
      navigate(`/signature?${params.toString()}`)

    })


  };


  return (
    <>

      <div id="heading-section">
        <img alt="" id="logo-heading" src="/logo.jpg"></img>
        <h1 id="form-heading"> Email Signature Creator </h1>


      </div>

      <div id="forms">
        {error && (
          <>

            <label id="form-heading" style={{ color: "#EF4444" }}>{error}</label> <br></br>
          </>
        )}
        <label id="form-heading" htmlFor="inputs-file">Details</label> <br></br>
        <input type="text" placeholder="Full Name" id="inputs" onChange={(e) => {
          setError(null)
          setName(e.target.value)
          }} /> <br></br>
        <input type="text" placeholder="Title" id="inputs" onChange={(e) => {
          setError(null)
          setTitle(e.target.value)
        }} /> <br></br>
        <input type="text" placeholder="Email" id="inputs" onChange={(e) => {
          setError(null)
          setEmail(e.target.value)}} /> <br></br>
          <br></br>
        <label id="form-heading" htmlFor="inputs-file">Optional Details</label> <br></br>
        <input type="text" placeholder="Phone Number" id="inputs" onChange={(e) => {
          setError(null)
          setPhoneNumber(e.target.value)}} /> <br></br>
        <br></br>
        <label id="form-heading" htmlFor="inputs-file">Image Upload</label> <br></br>
        <input type="file" id="inputs-file" onChange={onFileChange} /> <br></br>

        <button id="submit-button" onClick={() => generateSignature()}>Submit</button> <br></br>

      </div>


    </>
  )
}

export default Home